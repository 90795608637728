<template>

	<div class="body">
		<div class="wrap">
			<div class="wrap-left">
				<complainLeft :active="menuIndex" @current="Changecurrent">  </complainLeft>
			</div>
			
			 <div class="wrap-right">
				  <quality :orderId="orderId" v-if="menuIndex=='0'"></quality>
				  <delivery  v-if="menuIndex=='1'" :orderId="orderId"></delivery>
				  <service  v-if="menuIndex=='2'" :orderId="orderId"></service>
			</div>
		</div>

	</div>
</template>

<script>
	import navTop from "@/components/navTop";
	import complainLeft from "@/components/ComplainLeft";
	import baseURl from "../../api/baseURl.js";
	import quality from "./components/quality.vue";
	import delivery from "./components/delivery.vue";
	import service from "./components/service.vue";
	import {
		mapGetters,
		mapState
	} from "vuex";
	import {
		getToken
	} from "@/utils/auth";
	export default {
		components: {
			navTop,
			complainLeft,
			quality,
			delivery,
			service
		},
		data() {
			return {
                  menuIndex:'0',
				  orderId:''
			}
		},
		created(){
			this.orderId = this.$route.query.id ? this.$route.query.id : '';
		},
		methods: {
			Changecurrent(e) {
				this.menuIndex=String(e);
			}
		},
	}
</script>

<style  scoped>
.body {
	background: #F1F1F1;
	height: 100vh;
}

.wrap {
  margin:auto;
  padding-top: 80px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  
  
}

.wrap-left {
  font-size: 14px;
  width: 20%;
  min-height: 800px;
  background-color: #fff;
  padding: 20px 15px 40px;
}

.wrap-right {
  width: 78%;
  min-height: 800px;
  background-color: #fff;
}

.form{
	width:500px;
	margin: 40px;
}
</style>
