<template>
	<el-row class="tac">
	  <el-col :span="13">
			<el-menu :default-active="active" class="el-menu-vertical-demo"  style="border:none;width:200px;text-align: center;">
				<el-menu-item :index="index.toString()" v-for="(item,index) in nav" :key="index" @click="navTo(index)">
					<i :class="item.icon"></i>
					<span slot="title"  class="title-font">{{ $lang(item, "text") }}</span>
				</el-menu-item>
			</el-menu>
	  </el-col>
	</el-row>
</template>
<script>
	export default {
		props:{
			active:{
			    type:[Number,String],
				default:''
			},
		},
		data() {
			return {
				nav: [
					{
					  icon: '',
					  textZhCn: "质量投诉",
					  textEnUs: "zhiliang",
					  
					  
					},
					{
					  icon: '',
					  textZhCn: "交期投诉",
					  textEnUs: "jiaoqi",
					  
					},
					{
					  icon: '',
					  textZhCn: "服务投诉",
					  textEnUs: "fuwu",
					  
					},
				],
			}
		},
		
		methods:{
			handleOpen(key, keyPath) {
				
			},
			handleClose(key, keyPath) {
				
			},
			navTo(index){
			    this.$emit("current",index) 
			},
			
		}
	}
</script>

<style scoped>
	.title-font{
		font-size: 16px;
		font-weight: 400;
	}
	.is-active{
		color: #000;
		
	}
	.is-active span{
		font-weight: 600;
	}
</style>
