import fetch from "@/api/fetch";

const u = "/client/complaint/";

export function complain(datas) {
    return fetch({
      url: `${u}post-complaint`,
      method: "post",
      data:datas
    })
  }
  