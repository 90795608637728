<template>
	<div class="form">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
			<el-form-item :label="$t('complain.goodsOrderSn')" prop="orderId">
				<el-input v-model="ruleForm.orderId"></el-input>
			</el-form-item>
			<el-form-item :label="$t('complain.complainType')" prop="qualityType" >
				<el-select v-model="ruleForm.qualityType" :placeholder="$t('operation.pleaseSelect')">
					<el-option :label="item.text" :value="item.value" v-for="item in commplainList" :key="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$t('complain.desc')" prop="description">
				<el-input type="textarea" v-model="ruleForm.description"></el-input>
			</el-form-item>

			<el-form-item :label="$t('complain.aboutImage')" prop="images">
				<upload ref="upload"></upload>
			</el-form-item>
			<el-form-item :label="$t('complain.rateval')">
				<el-rate v-model="ruleForm.stars" class="rateval"></el-rate>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="sumbit('ruleForm')" style="width: 100%">{{
                  $t("operation.confirmSubmit")
                }}
				</el-button>
			</el-form-item>
		</el-form>
	</div>

</template>
<script>
	import upload from "@/components/upload";
	import baseURl from "@/api/baseURl.js";
	import {
		mapGetters,
		mapState
	} from "vuex";
	import {
		complain
	} from '@/api/manager';
	import {
		complainList
	} from '@/api/common';
	import {
		getToken
	} from "@/utils/auth";

	export default {
		components: {
			upload
		},
		props:{
			orderId:{
				type:String,
				default:''
			}
		},

		data() {
			return {
				url: baseURl.url,
				Authorization: getToken(),
				commplainList: [],
				ruleForm: {
					orderId: "",
					qualityType: "",
					description: "",
					phone: "",
					stars: 5,
					type:1
				},
				rules: {
					orderId: [{
						required: true,
						message: this.$t("complain.mesGOS"),
						trigger: "blur",
					}, ],
					qualityType: [{
						required: true,
						message: this.$t("complain.mesComplainType"),
						trigger: "change",
					}, ],
					description: [{
						required: true,
						message: this.$t("complain.mesDesc"),
						trigger: "blur",
					}, ],
					imageList: [{
						required: true,
						message: this.$t("complain.mesDesc"),
						trigger: "blur",
					}],

				},
				dialogImageUrl: "",
				dialogVisible: false,
				disabled: false,
				rateval: null,

			};
		},
		computed: {
			...mapState(['userInfo'])
		},
		created() {
            this.ruleForm.orderId=this.orderId;
			this.ruleForm.phone = this.userInfo.phone;
			complainList("complaint_quality_type").then(res => {
				this.commplainList = res
			}).catch(err => {

			})
		},
		methods: {
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			sumbit(formName) {
		
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.image=this.$refs.upload.imageList.map(v=>v.pic).join(',');
						complain(this.ruleForm).then(res=>{
							this.$message({
								message: '投诉成功',
								type: 'success',
								onClose: (res) => {
									this.$router.push({path: "/orderadmin"},()=>{
									  location.reload()
									});
								}
							});
							
							
						}).catch(err=>{
							
						})
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>
<style scoped>

	.form {
		width: 500px;
		margin: 40px;
	}


	.rateval {
		position: relative;
		top: 10px;
	}
</style>
