<template>

	<div class="wrap-right">
		<div class="form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
				<el-form-item :label="$t('complain.desc')" prop="desc">
					<el-input type="textarea" v-model="ruleForm.desc"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="sumbit('ruleForm')" style="width:100%">
						{{ $t('operation.confirmSubmit') }}</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>

</template>
<script>
	import {
		complain
	} from '@/api/manager';
	import {
		mapGetters,
		mapState
	} from "vuex";

	export default {
		data() {
			return {

				ruleForm: {
					type: 3,
					phone: '',
					desc: '',
				},
				rules: {

					desc: [{
						required: true,
						message: this.$t("complain.mesDesc"),
						trigger: 'blur'
					}, ],

				},

				rateval: null,
			}
		},
		computed: {
			...mapState(['userInfo'])
		},
		created() {
			this.ruleForm.phone = this.userInfo.phone;
		},

		methods: {
			sumbit(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						complain(this.ruleForm).then(res => {
							this.$message({
								message: '投诉成功',
								type: 'success',
								onClose: (res) => {
									this.$router.push({
										path: "/"
									}, () => {
										location.reload()
									});
								}
							});


						}).catch(err => {

						})
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		}
	}
</script>
<style scoped>
	.form {
		width: 500px;
		margin: 40px;
	}
</style>
